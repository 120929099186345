<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.required"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        flat
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="toperacioncrm"
      :rules="formRules.required"
    >
      <b10-autocomplete
        v-model="form.toperacioncrm"
        :items="tiposOperacionCRM"
        item-text="descripcion"
        label="Tipo"
        clearable
        :error-messages="errors"
        return-object
        no-filter
      />
    </ValidationProvider>
  </b10-base>
</template>

<script>
import formMixin from "@/mixins/formMixin"
import Data from "./OperacioncrmSimpleFormData"
import _ from "@/utils/lodash"

export default {
  mixins: [formMixin],
  props: {
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
    descripcion: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        descripcion: this.descripcion,
        toperacioncrm: null,
        observaciones: null,
      },
      formRules: {
        required: { required: true },
      },
      tiposOperacionCRM: [],
    }
  },
  async created() {
    const resp = await Data.selectLookups(
      this,
      this.esTecnico,
      this.esComercial,
      this.esVigilante
    )
    this.tiposOperacionCRM = resp.data.selectToperacionCRM.result.dataset
    if (this.tiposOperacionCRM.length === 1) {
      this.form.toperacioncrm = _.find(this.tiposOperacionCRM, {
        idtoperacioncrm: resp.data.selectToperacionCRM.result.dataset[0].idtoperacioncrm,
      })
    }
    await this.loadForm()
  },
}
</script>
